$mobile-width: 600px;

:root {
  --mobile-width: #{$mobile-width};
}

@mixin mobile {
  @media screen and (max-width: $mobile-width){
    @content
  }
}
