@import "../../style/constants";
@import "../../style/breakpoints";

.contact-form-panel {
  $green: #008582;
  color: white;

  &.collapsed {
    background: $bk-mid;
    border-right: 9px solid $green;

    .toggle-btn {
      display: flex;
      background: $green;
      flex-direction: column;
      align-items: center;
      padding: 6px 4px;
      margin-top: 18px;
      font-weight: 500;
      font-size: 14px;

      i {
        margin-bottom: 6px;
      }

      span {
        display: inline-block;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        transform: rotate(180deg);
      }
    }
  }


  &:not(.collapsed) {
    background: $green;
    width: 300px;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;

    .toggle-btn {
      display: table;
      line-height: 24px;
      text-align: center;

      width: 24px;
      background: rgba(255,255,255,0.15);
      border-radius: 2px;

      i {
        vertical-align: middle;
      }

      span {
        display: none;
      }
    }
  }
}

.contact-form {
  flex: 1;
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 500;
    font-size: 20px;
    margin: 16px 0px;
  }

  .description {
    font-size: 16px;
    opacity: 0.7;
    margin-bottom: 52px;
  }

  label {
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 12px;
  }

  input, textarea {
    background: none;
    display: block;
    width: 100%;
    color: rgba(255,255,255,0.8);
    appearance: none;
    border: none;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    outline: none;

    &::placeholder {
      color: rgba(255,255,255,0.5);
    }
  }

  textarea {
    height: 100px;
    resize: none;
  }

  .security-text {
    font-size: 12px;
    color: #FFFFFF;
    opacity: 0.7;
    margin-top: auto;
    margin-bottom: 28px;
  }

  .send-btn {
    line-height: 54px;
    display: block;
    text-align: center;
    margin-top: auto;

    font-weight: 500;
    font-size: 14px;
    color: black;
    background: white;
    margin: 0px -20px;
    text-transform: uppercase;
    position: relative;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

@media (max-height: 775px) {
  .contact-form {
    h1 {
      margin-top: 6px;
      margin-bottom: 6px;
    }

    .description {
      margin-bottom: 16px;
    }

    textarea {
      height: 50px;
    }
    .security-text {
      margin-bottom: 12px;
    }
  }
}

.mobile-contact-btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  line-height: 54px;
  background: #008582;
  z-index: 10;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
}

@include mobile {
  .mobile-contact-form {
    position: fixed;
    left: 0px;
    top:0px;
    width: 100%;
    height: 100%;
    background: #008582;
    z-index: 10000;
    color: white;

    padding-top: 66px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;

    .close-btn {
      position: absolute;
      right: 16px;
      top: 16px;
      font-size: 32px;
    }

    .contact-form {
      flex: 1;

      h1 {
        max-width: 70%;
        margin-bottom: 16px;
      }

      .description {
        margin-bottom: 52px;
        max-width: 70%;
      }
    }
  }

}